<template>
  <v-autocomplete
    :value="value"
    @change="handleChange"
    v-bind="$attrs"
    :items="unities"
    :loading="loading"
    return-object
    item-text="name"
    item-value="id"
    :search-input.sync="search"
    no-filter
    hide-no-data
    clearable
  ></v-autocomplete>
</template>

<script>
import { index } from "@/services/unities";

export default {
  props: {
    value: Object,
  },

  data() {
    return {
      loading: false,
      unities: [],
      search: "",
    };
  },

  methods: {
    handleChange(event) {
      console.log(event);
      this.$emit("input", event);
    },
    async getUnities() {
      this.loading = true;

      try {
        const response = await index({
          filter: this.search,
          page: 1,
          perPage: 100,
          orderBy: "name",
        });
        this.unities = response;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    async handleFocus() {
      if (this.unities.length > 0) {
        return;
      }

      this.getUnities("");
    },
  },

  watch: {
    async search(value) {
      if (this.loading === true) {
        return;
      }

      this.loading = true;
      setTimeout(async () => {
        await this.getUnities();
      }, 1000);
    },
  },
};
</script>
