import Vue from "vue";

export async function getCountries() {
  const response = await Vue.prototype.$http.get(`/countries`);

  return response.data;
}

export async function getCountryAreas(country_code) {
  const response = await Vue.prototype.$http.get(
    `/countries/${country_code}/areas`
  );

  return response.data;
}

export async function getCities(country_area_id) {
  const response = await Vue.prototype.$http.get(
    `/country_areas/${country_area_id}/cities`
  );

  return response.data;
}

export async function getAllCities() {
  const response = await Vue.prototype.$http.get(`/cities`);

  return response.data;
}

export async function getCityAreas(city_id) {
  const response = await Vue.prototype.$http.get(`/cities/${city_id}/areas`);

  return response.data;
}

export async function getPostalAddresses(country_code, params = {}) {
  const response = await Vue.prototype.$http.get(
    `/countries/${country_code}/postal_addresses`,
    {
      params,
    }
  );

  return response.data;
}

export async function autocompleteZipCode(country_code, postal_code) {
  const response = await Vue.prototype.$http.get(
    `/countries/${country_code}/${postal_code}`
  );

  return response.data;
}
