import Vue from "vue";

export async function createForCountryAreas(
  unity_id,
  service_type_id,
  payload
) {
  const response = await Vue.prototype.$http.post(
    `unity_service_types/${unity_id}/${service_type_id}/country_areas`,
    payload
  );

  return response.data;
}

export async function createForCities(
  unity_id,
  service_type_id,
  country_area_id,
  payload
) {
  const response = await Vue.prototype.$http.post(
    `unity_service_types/${unity_id}/${service_type_id}/${country_area_id}/cities`,
    payload
  );

  return response.data;
}

export async function copyFrom(unity_id, payload) {
  const response = await Vue.prototype.$http.post(
    `unity_service_types/${unity_id}/copy`,
    payload
  );

  return response.data;
}

export async function indexAvailabilities(
  unity_id,
  service_type_id,
  country_area_id
) {
  const response = await Vue.prototype.$http.get(
    `unity_service_types/${unity_id}/${service_type_id}/${country_area_id}`
  );

  return response.data;
}

export async function indexForCity(service_type_id, city_id) {
  const response = await Vue.prototype.$http.get(
    `unity_service_types/${service_type_id}/${city_id}`
  );

  return response.data;
}

/** Retorna todas as city_ids e country_area_ids da unidade, utilizado para filtrar score de distribuição */
export async function indexForUnity(service_type_id, unity_id) {
  const response = await Vue.prototype.$http.get(
    `unity_service_types/${service_type_id}/unity/${unity_id}`
  );

  return response.data;
}

export async function updateScore(service_type_id, city_id, unity_id, payload) {
  const response = await Vue.prototype.$http.put(
    `unity_service_types/${service_type_id}/${city_id}/${unity_id}`,
    payload
  );

  return response.data;
}
