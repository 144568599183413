<template>
  <v-list>
    <v-subheader v-if="title"> {{ title }} </v-subheader>
    <v-list-item
      v-for="item in filteredItems"
      :key="item.id"
      link
      @click="handleClick(item)"
      :input-value="value && value.id === item.id"
      active-class="primary--text"
    >
      <v-list-item-content>
        <v-list-item-title> {{ item.name }} </v-list-item-title>
      </v-list-item-content>
      <v-list-item-icon>
        <v-icon> mdi-chevron-right </v-icon>
      </v-list-item-icon>
    </v-list-item>
  </v-list>
</template>

<script>
import { getCountryAreas } from "@/services/locals";

export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    value: {
      type: Object,
      default: () => null,
    },
    onlyIds: {
      type: Array,
      default: () => null,
    },
  },

  data() {
    return {
      items: [],
      loading: false,
    };
  },

  methods: {
    handleClick(item) {
      this.$emit("input", item);
    },

    async getItems() {
      if (this.items.length > 0) {
        return;
      }

      this.loading = true;
      try {
        this.items = await getCountryAreas("pt");
      } catch (error) {
        this.error = true;
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },

  computed: {
    filteredItems() {
      if (this.onlyIds === null) {
        return this.items;
      }

      return this.items.filter((item) => {
        return this.onlyIds.includes(item.id);
      });
    },
  },

  created() {
    this.getItems();
  },
};
</script>
