<template>
  <div style="max-width: 1050px">
    <div class="text-h5">Distribuição de score</div>
    <v-card class="mt-10" outlined color="white">
      <v-card-text class="pa-0" style="height: calc(100vh - 170px)">
        <v-row dense style="height: 100%">
          <v-col cols="4" style="height: 100%">
            <v-list-item class="my-1">
              <v-list-item-content>
                <v-list-item-title class="title">
                  Parâmetros
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <div style="height: calc(100% - 53px); overflow-y: auto">
              <v-select
                v-model="service_type_id"
                :items="[
                  {
                    text: 'Danos por água',
                    value: 1,
                  },
                  {
                    text: 'Riscos elétricos',
                    value: 2,
                  },
                ]"
                label="Tipo de serviço"
                filled
                class="ma-2"
                hide-details
                single-line
                item-value="value"
                item-text="text"
              ></v-select>
              <UnityAutocomplete
                v-model="unity"
                class="ma-2"
                filled
                prepend-inner-icon="mdi-filter"
                label="Unidade (opcional)"
              />
              <DistrictCitiesExpansionPanel
                class="pa-2"
                v-model="district"
                :onlyIds="onlyCountryAreas"
                title="DISTRITOS"
              />
            </div>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col style="height: 100%">
            <v-list-item class="my-1">
              <v-list-item-content>
                <v-list-item-title class="title"> Unidades </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <div style="height: calc(100% - 53px); overflow-y: auto">
              <CitiesList
                :countryAreaId="districtId"
                v-model="city"
                :onlyIds="onlyCities"
              >
                <template v-slot="{ item, active }">
                  <div>
                    <v-list-item
                      @click="city = item"
                      link
                      :input-value="active"
                      active-class="primary--text"
                    >
                      <v-list-item-content>
                        <v-list-item-title> {{ item.name }} </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-icon>
                        <v-icon v-if="active"> mdi-chevron-down </v-icon>
                        <v-icon v-else> mdi-chevron-right </v-icon>
                      </v-list-item-icon>
                    </v-list-item>
                    <v-list dense v-if="active" class="mx-4">
                      <v-subheader>
                        UNIDADES
                        <v-btn
                          @click="getUnitiesForCity(service_type_id, city.id)"
                          small
                          icon
                        >
                          <v-icon small>mdi-refresh</v-icon>
                        </v-btn>
                      </v-subheader>
                      <v-list-item
                        v-for="availability in unities"
                        :key="availability.id"
                        :class="{ 'red--text': availability.score === 0 }"
                      >
                        <v-list-item-content>
                          <v-list-item-title>
                            <v-icon
                              :color="availability.score === 0 ? 'red' : ''"
                              left
                              >mdi-account</v-icon
                            >
                            {{ availability.unity.user.name }}
                          </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action style="width: 200px">
                          <v-slider
                            @change="
                              handleUpdateUnityScore(
                                availability.service_type_id,
                                availability.city_id,
                                availability.unity_id,
                                $event
                              )
                            "
                            v-model="availability.score"
                            :color="availability.score === 0 ? 'red' : ''"
                            max="100"
                            min="0"
                            step="5"
                            :label="availability.score.toString()"
                          ></v-slider>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                  </div>
                </template>
              </CitiesList>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import DistrictCitiesExpansionPanel from "@/components/Districts/DistrictCitiesExpansionPanel";
import CitiesList from "@/components/Cities/CitiesList";
import {
  indexForCity,
  updateScore,
  indexForUnity,
} from "@/services/unities/unity_service_types_service";
import UnityAutocomplete from "../../../components/unities/UnityAutocomplete.vue";

export default {
  components: {
    DistrictCitiesExpansionPanel,
    CitiesList,
    UnityAutocomplete,
  },

  props: {
    onlyIds: {
      type: Array,
      default: () => null,
    },
  },

  data() {
    return {
      service_type_id: 1,
      district: null,
      city: null,
      unities: [],
      unity: null,
      onlyCountryAreas: null,
      onlyCities: null,
    };
  },

  methods: {
    handleUpdateUnityScore(service_type_id, city_id, unity_id, score) {
      updateScore(service_type_id, city_id, unity_id, {
        score,
      });
    },
    async getUnitiesForCity(service_type_id, city_id) {
      this.unities = [];
      const response = await indexForCity(service_type_id, city_id);
      this.unities = response;
    },
    async getAreaRestriction(unity) {
      if (!unity) {
        this.onlyCountryAreas = null;
        this.onlyCities = null;
        return;
      }

      const response = await indexForUnity(this.service_type_id, unity.id);
      this.onlyCities = response.city_ids;
      this.onlyCountryAreas = response.country_area_ids;
    },
  },

  computed: {
    districtId() {
      if (!this.district) {
        return null;
      }

      return this.district.id;
    },
    onlyCountryAreaIds() {
      if (!this.unity) {
        return null;
      }

      return [];
    },
  },

  watch: {
    unity: function (value) {
      this.city = null;
      this.district = null;
      this.getAreaRestriction(value);
    },
    district(value) {
      this.city = null;
    },
    service_type_id(value) {
      this.city = null;
      this.district = null;
      if (this.unity) {
        this.getAreaRestriction(this.unity);
      }
    },
    async city(value) {
      this.unities = [];

      if (!value) {
        return;
      }

      this.getUnitiesForCity(this.service_type_id, value.id);
    },
  },
};
</script>
