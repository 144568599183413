<template>
  <v-list>
    <v-subheader v-if="title"> {{ title }} </v-subheader>
    <div v-for="item in filteredItems" :key="item.id">
      <slot
        :item="item"
        :active="value && value.id === item.id"
        :handleCLick="handleClick"
      >
        <v-list-item
          link
          @click="handleClick(item)"
          :input-value="value && value.id === item.id"
          active-class="primary--text"
        >
          <v-list-item-content>
            <v-list-item-title> {{ item.name }} </v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon> mdi-chevron-right </v-icon>
          </v-list-item-icon>
        </v-list-item>
      </slot>
    </div>
  </v-list>
</template>

<script>
import { getCities } from "@/services/locals";

export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    countryAreaId: {
      type: Number,
      default: null,
    },
    value: {
      type: Object,
      default: () => null,
    },
    onlyIds: {
      type: Array,
      default: () => null,
    },
  },

  data() {
    return {
      items: [],
      loading: false,
    };
  },

  methods: {
    handleClick(item) {
      this.$emit("input", item);
    },

    async getItems(countryAreaId) {
      this.items = [];

      this.loading = true;
      try {
        this.items = await getCities(countryAreaId);
      } catch (error) {
        this.error = true;
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },

  computed: {
    filteredItems() {
      if (this.onlyIds === null) {
        return this.items;
      }

      return this.items.filter((item) => {
        return this.onlyIds.includes(item.id);
      });
    },
  },

  watch: {
    async countryAreaId(value) {
      if (!value) {
        this.items = [];
        return;
      }
      this.getItems(value);
    },
  },
};
</script>
